import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from 'react-router-dom';
import { HomePage, AdminPage, UploadsPage, BillboardPage, EditBillboardPage, UploadersPage, AddContentPage, EditContentPage } from './Pages';
import './style.css';

const router = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '/admin',
        element: <AdminPage />,
    },
    {
        path: '/admin/uploads',
        element: <UploadsPage />,
    },
    {
        path: '/admin/billboard',
        element: <BillboardPage />,
    },
    {
        path: '/admin/billboard/:type',
        element: <BillboardPage />,
    },
    {
        path: '/admin/billboard/:type/edit/:index',
        element: <EditBillboardPage />,
    },
    {
        path: '/admin/uploaders',
        element: <UploadersPage />,
    },
    {
        path: '/add',
        element: <AddContentPage />,
    },
    {
        path: '/edit/:id',
        element: <EditContentPage />,
    },
    {
        path: '/*',
        element: <Navigate to='/' replace />,
    }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
