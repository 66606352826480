import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import { unstable_batchedUpdates } from "react-dom";

function Uploaders() {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch('/api/uploaders/', {
            method: 'POST',
            body: JSON.stringify({
                auth: localStorage.getItem('auth')
            })
        }).then(async response => {
            if (response.status === 200) {
                setData(await response.json());
            } else if (response.status === 401) {
                navigate('/');
            }
        });
    }, []);

    return (
        <Box sx={{
            minHeight: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                margin: '20px 10px',
                padding: '0px 10px',
                fontSize: '41px',
                color: '#e1e6f0',
                alignSelf: 'flex-start',
                cursor: 'pointer'
            }} onClick={() => navigate('/admin')}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            <Box sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <Box sx={{
                    width: '100%',
                    maxWidth: '800px'
                }} component="table">
                    <Box component="tr">
                        <Box sx={{
                            textAlign: 'left'
                        }} component="th">Name</Box>
                        <Box sx={{
                            textAlign: 'left'
                        }} component="th">Email</Box>
                        <Box sx={{
                            textAlign: 'left'
                        }} component="th">Suspended</Box>
                        <Box sx={{
                            textAlign: 'left'
                        }} component="th">Uploads</Box>
                    </Box>
                    {data.map((uploader, i) => (
                        <Box component="tr">
                            <Box sx={{
                                userSelect: 'text'
                            }} component="td">{uploader.name}</Box>
                            <Box sx={{
                                userSelect: 'text'
                            }} component="td">{uploader.email}</Box>
                            <Box component="td">{uploader.suspended ? 'Yes' : 'No'}</Box>
                            <Box component="td">{uploader.uploads}</Box>
                            <Button sx={{
                                minWidth: '110px'
                            }} disabled={isLoading} onClick={() => {
                                setLoading(true);
                                fetch(`/api/uploaders/${uploader.suspended ? 'unsuspend' : 'suspend'}/`, {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        id: uploader.id,
                                        auth: localStorage.getItem('auth')
                                    })
                                }).then(async response => {
                                    if (response.status === 200) {
                                        unstable_batchedUpdates(() => {
                                            const newData = [...data];
                                            newData[i].suspended = !newData[i].suspended;

                                            setData(newData);
                                            setLoading(false);
                                        });
                                    } else if (response.status === 401) {
                                        navigate('/');
                                    } else {
                                        setLoading(false);
                                    }
                                }).catch(() => setLoading(false));
                            }}>{uploader.suspended ? 'Unsuspend' : 'Suspend'}</Button>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default Uploaders;
