import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowsSpin } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import { unstable_batchedUpdates } from "react-dom";

function formatDuration(milliseconds) {
    let remainingMilliseconds = milliseconds;

    const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));
    remainingMilliseconds %= 1000 * 60 * 60;

    const minutes = Math.floor(remainingMilliseconds / (1000 * 60));
    remainingMilliseconds %= 1000 * 60;

    return `${hours}h ${minutes}m`;
}

function Billboard() {
    const { type } = useParams();
    const navigate = useNavigate();

    if (type != null && type !== 'movies' && type !== 'shows') {
        navigate('/admin/billboard');
    }

    const [isLoading, setLoading] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [activeBannerIndex, setActiveBannerIndex] = useState(0);
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        if (type != null) {
            setLoading(true);
            fetch('/api/content/billboard/', {
                method: 'POST',
                body: JSON.stringify({
                    auth: localStorage.getItem('auth'),
                    type: type === 'movies' ? 'movie' : 'series'
                })
            }).then(async response => {
                const banners = await response.json();

                unstable_batchedUpdates(() => {
                    setBanners(banners);
                    setLoading(false);
                });
            }).catch(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [type]);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Box sx={{
            minHeight: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                zIndex: 999,
                margin: '20px 10px',
                padding: '0px 10px',
                fontSize: '41px',
                color: '#e1e6f0',
                alignSelf: 'flex-start',
                cursor: 'pointer'
            }} onClick={() => navigate(type == null ? '/admin' : '/admin/billboard')}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            {isLoading ? (
                <Box sx={{
                    zIndex: 998,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        fontSize: '64px',
                        color: '#ddc36c',
                        '@keyframes spin': {
                            '0%': {
                                transform: 'rotate(0deg)'
                            },
                            '100%': {
                                transform: 'rotate(360deg)'
                            }
                        },
                        'svg': {
                            animation: 'spin 1.1s linear infinite'
                        }
                    }}>
                        <FontAwesomeIcon icon={faArrowsSpin} />
                    </Box>
                </Box>
            ) : (
                type == null ? (
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <Button sx={{
                            padding: '8px 14px',
                            borderRadius: '8px',
                            alignSelf: 'center',
                            fontSize: '16px',
                            color: '#ffffff !important',
                            background: 'linear-gradient(93.87deg,#2980b9,#3498db)'
                        }} onClick={() => navigate('/admin/billboard/movies')}>Movies Billboard</Button>
                        <Button sx={{
                            marginTop: '20px',
                            padding: '8px 14px',
                            borderRadius: '8px',
                            alignSelf: 'center',
                            fontSize: '16px',
                            color: '#ffffff !important',
                            background: 'linear-gradient(93.87deg,#c0392b,#e74c3c)'
                        }} onClick={() => navigate('/admin/billboard/shows')}>TV Shows Billboard</Button>
                    </Box>
                ) : (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        '.billboard': {
                            marginBottom: '10px',
                            width: '100%'
                        },
                        '.banner': {
                            display: 'flex !important',
                            justifyContent: 'center !important'
                        }
                    }}>
                        <Swiper className="billboard" pagination={{
                            clickable: true,
                        }} autoplay={{
                            delay: 4500,
                            disableOnInteraction: false,
                            waitForTransition: true
                        }} centeredSlides={true} modules={[Pagination]} slidesPerView={screenWidth >= 1920 ? 1.2 : screenWidth >= 1280 ? 1.1 : 1} loop={true} onSlideChangeTransitionEnd={(swiper) => setActiveBannerIndex(swiper.realIndex)}>
                            {Array(5).fill(0).map((_, i) =>
                                banners.length < i + 1 ? (
                                    <SwiperSlide key={`em${i}`} className="banner">
                                        <Box sx={{
                                            background: '#000000',
                                            borderRadius: '0px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '400px',
                                            '@media screen and (max-width: 479px)': {
                                                height: '200px'
                                            },
                                            '@media screen and (min-width: 960px)': {
                                                height: '400px',
                                                justifyContent: 'unset',
                                                borderRadius: '8px',
                                                border: '1px solid #000000'
                                            },
                                            '@media screen and (min-width: 1920px)': {
                                                height: '500px'
                                            },
                                            ...(screenWidth < 960 ? {
                                                alignItems: 'flex-end',
                                                paddingBottom: '45%'
                                            } : {
                                                position: 'relative',
                                                alignItems: 'center',
                                                boxShadow: '0 2px 8px 3px #000000',
                                                margin: '10px'
                                            })
                                        }}>
                                            <Box sx={{
                                                borderRadius: '0px',
                                                position: 'absolute',
                                                bottom: -1,
                                                top: -1,
                                                right: -1,
                                                left: -1,
                                                background: 'unset',
                                                justifyContent: 'center',
                                                '@media screen and (min-width: 960px)': {
                                                    borderRadius: '8px',
                                                    background: 'linear-gradient(180deg,rgba(15,16,20,0) 73.08%,rgba(15,16,20,.01) 77.84%,rgba(15,16,20,.05) 79.74%,#0f1014),linear-gradient(90deg,#0f1014,rgba(15,16,20,.8) 7.31%,rgba(15,16,20,.52) 15.54%,rgba(15,16,20,.12) 29.73%,rgba(15,16,20,0) 40.59%)'
                                                }
                                            }}></Box>
                                            <Box sx={{
                                                borderRadius: '0px',
                                                '@media screen and (min-width: 960px)': {
                                                    borderRadius: '8px'
                                                },
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                justifyContent: 'flex-end',
                                                position: 'absolute',
                                                paddingBottom: '20px',
                                                paddingRight: '20px',
                                                bottom: -1,
                                                right: -1,
                                                left: -1,
                                                background: 'linear-gradient(180deg,rgba(15,16,20,0),#0f1014)',
                                                height: screenWidth < 960 ? '100%' : '25%'
                                            }}>
                                                {screenWidth >= 960 ? Array(5).fill(0).map((_, j) =>
                                                    <Box key={`vc${j}`} sx={{
                                                        background: j === i ? '#ffffff' : '#4b5265',
                                                        width: j === i ? '7px' : j === i - 1 ? '6px' : j === i + 1 ? '6px' : '5px',
                                                        height: j === i ? '7px' : j === i - 1 ? '6px' : j === i + 1 ? '6px' : '5px',
                                                        borderRadius: '50%',
                                                        marginLeft: j === 0 ? '0px' : '7.5px'
                                                    }}></Box>
                                                ) : null}
                                            </Box>
                                            <Button sx={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                backgroundColor: '#23262b !important',
                                                color: '#ffffff',
                                                borderRadius: '8px'
                                            }} onClick={() => navigate(`/admin/billboard/${type}/edit/${i + 1}`)}>Edit</Button>
                                        </Box>
                                    </SwiperSlide>
                                ) : (
                                    <SwiperSlide key={`nem${banners[i].id}`} className="banner">
                                        <Box sx={{
                                            borderRadius: '0px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            backgroundImage: 'url(https://img.arabiflix.com/' + banners[i].image + ')',
                                            backgroundPositionY: 'center',
                                            backgroundPositionX: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            width: '100%',
                                            height: '400px',
                                            '@media screen and (max-width: 479px)': {
                                                height: '200px',
                                                ...banners[i].css['@media screen and (max-width: 479px)']
                                            },
                                            '@media screen and (min-width: 960px)': {
                                                height: '400px',
                                                justifyContent: 'unset',
                                                borderRadius: '8px',
                                                border: '1px solid #000000',
                                                ...banners[i].css['@media screen and (min-width: 960px)']
                                            },
                                            '@media screen and (min-width: 1920px)': {
                                                height: '500px',
                                                ...banners[i].css['@media screen and (min-width: 479px)']
                                            },
                                            ...(screenWidth < 960 ? {
                                                alignItems: 'flex-end',
                                                backgroundPositionY: '0px',
                                                paddingBottom: '45%'
                                            } : {
                                                position: 'relative',
                                                alignItems: 'center',
                                                boxShadow: '0 2px 8px 3px #000000',
                                                margin: '10px'
                                            }),
                                            ...(banners[i].css ? (() => {
                                                const css = { ...banners[i].css };
                                                delete css['@media screen and (max-width: 479px)'];
                                                delete css['@media screen and (min-width: 960px)'];
                                                delete css['@media screen and (min-width: 479px)'];
                                                return css;
                                            })() : null),
                                            ...(screenWidth < 960 ? {
                                                backgroundSize: 'cover',
                                            } : {})
                                        }}>
                                            <Box sx={{
                                                borderRadius: '0px',
                                                position: 'absolute',
                                                bottom: -1,
                                                top: -1,
                                                right: -1,
                                                left: -1,
                                                background: 'unset',
                                                justifyContent: 'center',
                                                '@media screen and (min-width: 960px)': {
                                                    borderRadius: '8px',
                                                    background: 'linear-gradient(180deg,rgba(15,16,20,0) 73.08%,rgba(15,16,20,.01) 77.84%,rgba(15,16,20,.05) 79.74%,#0f1014),linear-gradient(90deg,#0f1014,rgba(15,16,20,.8) 7.31%,rgba(15,16,20,.52) 15.54%,rgba(15,16,20,.12) 29.73%,rgba(15,16,20,0) 40.59%)'
                                                }
                                            }}></Box>
                                            <Box sx={{
                                                borderRadius: '0px',
                                                '@media screen and (min-width: 960px)': {
                                                    borderRadius: '8px'
                                                },
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                justifyContent: 'flex-end',
                                                position: 'absolute',
                                                paddingBottom: '20px',
                                                paddingRight: '20px',
                                                bottom: -1,
                                                right: -1,
                                                left: -1,
                                                background: 'linear-gradient(180deg,rgba(15,16,20,0),#0f1014)',
                                                height: screenWidth < 960 ? '100%' : '25%'
                                            }}>
                                                {screenWidth >= 960 ? Array(5).fill(0).map((_, j) =>
                                                    <Box key={`bvs${j}`} sx={{
                                                        background: j === i ? '#ffffff' : '#4b5265',
                                                        width: j === i ? '7px' : j === i - 1 ? '6px' : j === i + 1 ? '6px' : '5px',
                                                        height: j === i ? '7px' : j === i - 1 ? '6px' : j === i + 1 ? '6px' : '5px',
                                                        borderRadius: '50%',
                                                        marginLeft: j === 0 ? '0px' : '7.5px'
                                                    }}></Box>
                                                ) : null}
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                zIndex: 999,
                                                alignItems: 'center',
                                                '@media screen and (min-width: 960px)': {
                                                    marginLeft: '64px',
                                                    maxWidth: '300px',
                                                    alignItems: 'unset'
                                                },
                                                '@media screen and (min-width: 1280px)': {
                                                    maxWidth: '416px'
                                                },
                                                '@media screen and (min-width: 1920px)': {
                                                    maxWidth: '586px'
                                                },
                                                ...(screenWidth < 960 ? {
                                                    position: 'absolute',
                                                    bottom: 0
                                                } : {}),
                                            }}>
                                                <Box className="logo" sx={{
                                                    width: 'fit-content',
                                                    maxWidth: '-webkit-fill-available',
                                                    zIndex: 999,
                                                    marginBottom: '16px',
                                                    maxHeight: '148px',
                                                    '@media screen and (max-width: 479px)': {
                                                        maxHeight: '100px'
                                                    },
                                                    ...(screenWidth < 960 ? {
                                                        maxWidth: '350px'
                                                    } : {}),
                                                    ...(screenWidth < 560 ? {
                                                        maxWidth: '300px'
                                                    } : {}),
                                                    ...(screenWidth < 360 ? {
                                                        maxWidth: '250px'
                                                    } : {}),
                                                    ...(screenWidth < 330 ? {
                                                        maxWidth: '200px'
                                                    } : {}),
                                                }} component="img" src={`https://img.arabiflix.com/${banners[i].logo}`}></Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '16px',
                                                    fontSize: '12px',
                                                    background: 'rgb(0 0 0 / 58%)',
                                                    borderRadius: '8px',
                                                    padding: '8px',
                                                    '@media screen and (min-width: 330px)': {
                                                        fontSize: '12px'
                                                    },
                                                    '@media screen and (min-width: 360px)': {
                                                        fontSize: '14px'
                                                    },
                                                    '@media screen and (min-width: 960px)': {
                                                        background: 'unset',
                                                        borderRadius: 'unset',
                                                        padding: 'unset'
                                                    },
                                                    '@media screen and (min-width: 1280px)': {
                                                        fontSize: '16px'
                                                    },
                                                    '@media screen and (min-width: 1920px)': {
                                                        fontSize: '25px'
                                                    }
                                                }}>
                                                    <Box sx={{
                                                        color: '#ffffff',
                                                        fontWeight: 600
                                                    }}>{banners[i].year}</Box>
                                                    <Box sx={{
                                                        background: '#bdada3',
                                                        borderRadius: '50%',
                                                        width: '5px',
                                                        height: '5px',
                                                        marginLeft: '10px',
                                                        marginRight: '10px'
                                                    }}></Box>
                                                    <Box sx={{
                                                        color: '#ffffff',
                                                        fontWeight: 600
                                                    }}>{formatDuration(banners[i].duration)}</Box>
                                                    <Box sx={{
                                                        background: '#bdada3',
                                                        borderRadius: '50%',
                                                        width: '5px',
                                                        height: '5px',
                                                        marginLeft: '10px',
                                                        marginRight: '10px'
                                                    }}></Box>
                                                    <Box sx={{
                                                        color: '#ffffff',
                                                        fontWeight: 600,
                                                        background: 'hsla(0,0%,100%,.2)',
                                                        borderRadius: '4px',
                                                        paddingLeft: '8px',
                                                        paddingRight: '8px'
                                                    }}>{`${banners[i].maturityRating}+`}</Box>
                                                </Box>
                                                <Box sx={{
                                                    display: 'none',
                                                    color: 'rgb(255 255 255 / 74%)',
                                                    fontSize: '24px',
                                                    fontWeight: 'normal',
                                                    maxWidth: '480px',
                                                    marginBottom: '16px',
                                                    '@media screen and (min-width: 960px)': {
                                                        display: 'block',
                                                        fontSize: '14px'
                                                    },
                                                    '@media screen and (min-width: 1280px)': {
                                                        fontSize: '16px'
                                                    },
                                                    '@media screen and (min-width: 1920px)': {
                                                        fontSize: '25px'
                                                    }
                                                }}>{banners[i].description}</Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '12px',
                                                    '@media screen and (min-width: 330px)': {
                                                        fontSize: '12px'
                                                    },
                                                    '@media screen and (min-width: 360px)': {
                                                        fontSize: '12px'
                                                    },
                                                    '@media screen and (min-width: 430px)': {
                                                        fontSize: '12px'
                                                    },
                                                    '@media screen and (min-width: 960px)': {
                                                        fontSize: '14px'
                                                    },
                                                    '@media screen and (min-width: 1280px)': {
                                                        fontSize: '16px'
                                                    },
                                                    '@media screen and (min-width: 1920px)': {
                                                        fontSize: '25px'
                                                    }
                                                }}>
                                                    {banners[i].genres.map((genre, j) =>
                                                        <Box key={`bnf${j}`} sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                            <Box sx={{
                                                                textWrap: 'nowrap',
                                                                color: '#ffffff',
                                                                fontWeight: 600
                                                            }}>{genre}</Box>
                                                            {banners[i].genres.length === j + 1 ? null : (<Box sx={{
                                                                background: '#bdada3',
                                                                borderRadius: '50%',
                                                                width: '4px',
                                                                height: '4px',
                                                                marginLeft: '10px',
                                                                marginRight: '10px'
                                                            }}></Box>)}
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Button sx={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                backgroundColor: '#23262b !important',
                                                color: '#ffffff',
                                                borderRadius: '8px'
                                            }} onClick={() => navigate(`/admin/billboard/${type}/edit/${i + 1}`)}>Edit</Button>
                                        </Box>
                                    </SwiperSlide>
                                )
                            )}
                        </Swiper>
                        {screenWidth < 960 ? (
                            <>
                                <Box sx={{
                                    marginTop: '16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    {Array(5).fill(0).map((_, i) =>
                                        <Box key={`jrt${i}`} sx={{
                                            background: i === activeBannerIndex ? '#ffffff' : '#4b5265',
                                            width: i === activeBannerIndex ? '7px' : i === activeBannerIndex - 1 ? '6px' : i === activeBannerIndex + 1 ? '6px' : '5px',
                                            height: i === activeBannerIndex ? '7px' : i === activeBannerIndex - 1 ? '6px' : i === activeBannerIndex + 1 ? '6px' : '5px',
                                            borderRadius: '50%',
                                            marginLeft: i === 0 ? '0px' : '7.5px'
                                        }}></Box>
                                    )}
                                </Box>
                            </>
                        ) : null}
                    </Box>
                )
            )}
        </Box >
    )
}

export default Billboard;
