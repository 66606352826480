import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Box, TextField, Pagination } from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsSpin, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';

function formatDuration(milliseconds) {
    let remainingMilliseconds = milliseconds;

    const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));
    remainingMilliseconds %= 1000 * 60 * 60;

    const minutes = Math.floor(remainingMilliseconds / (1000 * 60));
    remainingMilliseconds %= 1000 * 60;
    return `${hours}h ${minutes}m`;
}

function Uploads() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState(location.state !== null && 'search' in location.state ? location.state.search : '');
    const [page, setPage] = useState(location.state !== null && 'page' in location.state ? location.state.page : 1);
    const [data, setData] = useState({
        count: 0,
        content: []
    });

    useEffect(() => {
        window.history.replaceState({}, '');
        setLoading(true);

        if (search.length > 0) {
            fetch('/api/content/search/', {
                method: 'POST',
                body: JSON.stringify({
                    auth: localStorage.getItem('auth'),
                    search: search,
                    page: page,
                    all: true
                })
            }).then(async response => {
                const data = await response.json();

                unstable_batchedUpdates(() => {
                    setData(data);
                    setLoading(false);
                });
            }).catch(() => setLoading(false));
        } else {
            fetch('/api/content/', {
                method: 'POST',
                body: JSON.stringify({
                    auth: localStorage.getItem('auth'),
                    page: page,
                    all: true
                })
            }).then(async response => {
                const data = await response.json();

                unstable_batchedUpdates(() => {
                    setData(data);
                    setLoading(false);
                });
            }).catch(() => setLoading(false));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Box sx={{
                zIndex: 999,
                margin: '20px 10px',
                padding: '0px 10px',
                fontSize: '41px',
                color: '#e1e6f0',
                alignSelf: 'flex-start',
                cursor: 'pointer',
                position: 'absolute'
            }} onClick={() => navigate('/admin')}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            <Box>
                <Box sx={{
                    zIndex: 999,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    display: isLoading ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        fontSize: '64px',
                        color: '#ddc36c',
                        '@keyframes spin': {
                            '0%': {
                                transform: 'rotate(0deg)'
                            },
                            '100%': {
                                transform: 'rotate(360deg)'
                            }
                        },
                        'svg': {
                            animation: 'spin 1.1s linear infinite'
                        }
                    }}>
                        <FontAwesomeIcon icon={faArrowsSpin} />
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                minHeight: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '326px',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignSelf: 'center',
                        marginBottom: '20px',
                        marginTop: '20px'
                    }}>
                        <TextField sx={{
                            flex: 1,
                            transition: 'opacity 0.4s',
                            width: '280px',
                            opacity: '1',
                            'input': {
                                WebkitTextFillColor: 'unset !important',
                                color: '#e1e6f0 !important'
                            },
                            'label': {
                                color: '#707a94 !important'
                            },
                            'fieldset': {
                                borderRadius: '8px',
                                borderColor: '#707a94 !important'
                            },
                            '.Mui-focused fieldset': {
                                borderColor: '#8f98b2 !important'
                            },
                            'label.MuiInputLabel-shrink': {
                                color: '#8f98b2 !important'
                            }
                        }} label="Search" autoComplete="off" value={search} onChange={({ currentTarget }) => {
                            unstable_batchedUpdates(() => {
                                setPage(1);
                                setSearch(currentTarget.value);
                            });

                            if (currentTarget.value.length > 0) {
                                fetch('/api/content/search/', {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        auth: localStorage.getItem('auth'),
                                        search: currentTarget.value,
                                        page: 1,
                                        all: true
                                    })
                                }).then(async response => {
                                    setData(await response.json());
                                }).catch(() => { });
                            } else {
                                fetch('/api/content/', {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        auth: localStorage.getItem('auth'),
                                        page: 1,
                                        all: true
                                    })
                                }).then(async response => {
                                    setData(await response.json());
                                }).catch(() => { });
                            }
                        }} />
                    </Box>
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            width: '100%',
                            alignSelf: 'center',
                            justifyContent: 'center',
                            marginBottom: '20px'
                        }}>
                            {data.content.map((_, i) => (
                                <Box sx={{
                                    transition: 'transform 0.2s',
                                    marginRight: '10px',
                                    marginLeft: '10px',
                                    marginBottom: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    backgroundColor: 'rgb(20, 20, 20)',
                                    height: 'fit-content',
                                    width: '291.203px',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    boxShadow: 'rgba(0, 0, 0, 0.75) 0px 3px 10px',
                                    cursor: 'pointer',
                                    '*': {
                                        pointerEvents: 'none'
                                    },
                                    ':hover': {
                                        transform: 'scale(1.03)'
                                    },
                                    ':active': {
                                        transform: 'scale(0.95)'
                                    }
                                }} onClick={() => navigate(`/edit/${data.content[i]['id']}`, { state: { admin: true, page: page, search: search } })}>
                                    <Box sx={{
                                        position: 'relative',
                                        height: '166.969px',
                                        overflow: 'hidden'
                                    }}>
                                        <Box sx={{
                                            width: '100%',
                                            height: '100%'
                                        }} component="img" src={`https://img.arabiflix.com/${data.content[i]['thumb']}`} loading="lazy"></Box>
                                    </Box>
                                    <Box sx={{
                                        padding: '12px'
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '0.8em'
                                        }}>
                                            <Box sx={{
                                                color: 'rgb(188, 188, 188)',
                                                width: '180px',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis'
                                            }}>{data.content[i]['title']}</Box>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: '#bcbcbc',
                                                marginLeft: 'auto'
                                            }}>
                                                <Box sx={{
                                                    fontSize: '14px',
                                                    marginRight: '4px'
                                                }}>{data.content[i]['views']}</Box>
                                                <VisibilityIcon sx={{
                                                    height: '16px'
                                                }} />
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: '14px'
                                        }}>
                                            <Box sx={{
                                                color: 'rgb(188, 188, 188)',
                                                marginRight: '0.5em'
                                            }}>{data.content[i]['year']}</Box>
                                            <Box sx={{
                                                color: 'rgb(188, 188, 188)',
                                                marginRight: '0.5em'
                                            }}>{data.content[i]['type'] === 'movie' ? formatDuration(data.content[i]['duration']) : data.content[i]['numEpisodes'] === undefined ? `${data.content[i]['numSeasons']} Seasons` : `${data.content[i]['numEpisodes']} Episodes`}</Box>
                                            <Box sx={{
                                                color: 'rgb(225, 230, 240)',
                                                border: '1px solid rgba(255, 255, 255, 0.4)',
                                                padding: '0px 0.4rem'
                                            }}>{data.content[i]['maturityRating']}+</Box>
                                            <Box sx={{
                                                marginLeft: 'auto',
                                                color: '#7f8c8d'
                                            }}>{data.content[i]['uploaderName']}</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Pagination sx={{
                        paddingBottom: '40px',
                        '.MuiPaginationItem-root': {
                            color: '#e1e6f0'
                        },
                        '.MuiButtonBase-root': {
                            backgroundColor: 'hsla(0,0%,100%,.2)',
                            color: '#ffffff'
                        },
                        '.MuiButtonBase-root:hover': {
                            backgroundColor: 'hsla(0,0%,100%,.3)'
                        },
                        '.MuiButtonBase-root.Mui-selected': {
                            backgroundColor: '#b19c56 !important'
                        }
                    }} count={data.count} page={page} siblingCount={0} size="large" variant="outlined" shape="rounded" onChange={(_, page) => {
                        setLoading(true);

                        if (search.length > 0) {
                            fetch('/api/content/search/', {
                                method: 'POST',
                                body: JSON.stringify({
                                    auth: localStorage.getItem('auth'),
                                    search: search,
                                    page: page,
                                    all: true
                                })
                            }).then(async response => {
                                const data = await response.json();

                                unstable_batchedUpdates(() => {
                                    setData(data);
                                    setPage(page);
                                    setLoading(false);
                                });
                            }).catch(() => setLoading(false));
                        } else {
                            fetch('/api/content/', {
                                method: 'POST',
                                body: JSON.stringify({
                                    auth: localStorage.getItem('auth'),
                                    page: page,
                                    all: true
                                })
                            }).then(async response => {
                                const data = await response.json();

                                unstable_batchedUpdates(() => {
                                    setData(data);
                                    setPage(page);
                                    setLoading(false);
                                });
                            }).catch(() => setLoading(false));
                        }
                    }} />
                </Box>
            </Box>
        </>
    );
}

export default Uploads;