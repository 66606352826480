import React from "react";
import { Box, Button } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';

function Admin() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Box sx={{
            minHeight: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                margin: '20px 10px',
                padding: '0px 10px',
                fontSize: '41px',
                color: '#e1e6f0',
                alignSelf: 'flex-start',
                cursor: 'pointer'
            }} onClick={() => navigate('/', { state: location.state })}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            <Box sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <Button sx={{
                    padding: '8px 14px',
                    borderRadius: '8px',
                    alignSelf: 'center',
                    fontSize: '16px',
                    color: '#ffffff !important',
                    background: 'linear-gradient(93.87deg,#2980b9,#3498db)'
                }} onClick={() => navigate('/admin/billboard')}>Manage Billboard</Button>
                <Button sx={{
                    marginTop: '20px',
                    padding: '8px 14px',
                    borderRadius: '8px',
                    alignSelf: 'center',
                    fontSize: '16px',
                    color: '#ffffff !important',
                    background: 'linear-gradient(93.87deg,#c0392b,#e74c3c)'
                }} onClick={() => navigate('/admin/uploaders')}>Manage Uploaders</Button>
                <Button sx={{
                    marginTop: '20px',
                    padding: '8px 14px',
                    borderRadius: '8px',
                    alignSelf: 'center',
                    fontSize: '16px',
                    color: '#ffffff !important',
                    background: 'linear-gradient(93.87deg,#2c3e50,#34495e)'
                }} onClick={() => navigate('/admin/uploads')}>Manage Uploads</Button>
            </Box>
        </Box>
    )
}

export default Admin;
