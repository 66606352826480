import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Select, MenuItem, InputLabel, FormControl, Breadcrumbs, Autocomplete } from "@mui/material";
import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddContent() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState({
        imdbId: '',
        type: 'movie',
        title: '',
        description: '',
        poster: '',
        thumb: '',
        logo: '',
        trailer: '',
        year: '',
        duration: '',
        maturityRating: '',
        genres: [],
        keywords: [],
        watchServers: [],
        seasons: [
            {
                title: '',
                description: '',
                thumb: '',
                trailer: '',
                keywords: [],
                episodes: [
                    {
                        title: '',
                        description: '',
                        thumb: '',
                        trailer: '',
                        duration: '',
                        keywords: [],
                        watchServers: []
                    }
                ]
            }
        ]
    });

    const onThumbDrop = acceptedFiles => {
        const reader = new FileReader();

        reader.onload = () => {
            setData({
                ...data,
                thumb: reader.result
            });
        }

        reader.readAsDataURL(acceptedFiles[0]);
    };

    const thumbDropzone = useDropzone({
        onDrop: onThumbDrop,
        accept: {
            'image/png': ['.png'],
            'image/webp': ['.webp'],
            'image/jpeg': ['.jpeg', '.jpg']
        }
    });

    const onPosterDrop = acceptedFiles => {
        const reader = new FileReader();

        reader.onload = () => {
            setData({
                ...data,
                poster: reader.result
            });
        }

        reader.readAsDataURL(acceptedFiles[0]);
    };

    const posterDropzone = useDropzone({
        onDrop: onPosterDrop,
        accept: {
            'image/png': ['.png'],
            'image/webp': ['.webp'],
            'image/jpeg': ['.jpeg', '.jpg']
        }
    });

    const onLogoDrop = acceptedFiles => {
        const reader = new FileReader();

        reader.onload = () => {
            setData({
                ...data,
                logo: reader.result
            });
        }

        reader.readAsDataURL(acceptedFiles[0]);
    };

    const logoDropzone = useDropzone({
        onDrop: onLogoDrop,
        accept: {
            'image/png': ['.png'],
            'image/webp': ['.webp'],
            'image/jpeg': ['.jpeg', '.jpg']
        }
    });

    const [seasonsBreadcrumbs, setSeasonsBreadcrumbs] = useState({
        seasonNum: null,
        episodeNum: null
    });

    const [genres, setGenres] = useState({});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        fetch('/api/content/genres/', {
            method: 'POST',
            body: JSON.stringify({
                auth: localStorage.getItem('auth')
            })
        }).then(async response => {
            if (response.status === 200) {
                setGenres(await response.json());
            } else if (response.status === 401) {
                navigate('/');
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{
            minHeight: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                margin: '20px 10px',
                padding: '0px 10px',
                fontSize: '41px',
                color: '#e1e6f0',
                alignSelf: 'flex-start',
                cursor: 'pointer'
            }} onClick={() => navigate('/', { state: location.state })}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            <Box sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{
                    padding: '40px 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '950px',
                    minWidth: '950px',
                    width: '100%',
                    alignSelf: 'center'
                }}>
                    <Box sx={{
                        display: 'flex',
                        marginBottom: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex'
                        }}>
                            <Box sx={{
                                marginRight: '20px',
                                pointerEvents: isLoading ? 'none' : 'unset',
                                opacity: isLoading ? '0.5 !important' : '1',
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '8px',
                                border: '2px dashed #707a94',
                                overflow: 'hidden',
                                cursor: 'pointer',
                                ':hover': {
                                    borderColor: '#8f98b2'
                                }
                            }} {...thumbDropzone.getRootProps()} onClick={(e) => {
                                setData({
                                    ...data,
                                    thumb: ''
                                });

                                thumbDropzone.getRootProps().onClick(e);
                            }}>
                                <Box component="input" {...thumbDropzone.getInputProps()} />
                                {data.thumb.length === 0 ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '389.92px',
                                        height: '219.53px'
                                    }}>THUMB</Box>
                                ) : (
                                    <Box sx={{
                                        width: '389.92px',
                                        height: '219.53px'
                                    }} component="img" src={data.thumb} />
                                )}
                            </Box>
                            <Box sx={{
                                pointerEvents: isLoading ? 'none' : 'unset',
                                opacity: isLoading ? '0.5 !important' : '1',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '8px',
                                border: '2px dashed #707a94',
                                marginRight: '20px',
                                overflow: 'hidden',
                                cursor: 'pointer',
                                ':hover': {
                                    borderColor: '#8f98b2'
                                }
                            }} {...posterDropzone.getRootProps()} onClick={(e) => {
                                setData({
                                    ...data,
                                    poster: ''
                                });

                                posterDropzone.getRootProps().onClick(e);
                            }}>
                                <Box component="input" {...posterDropzone.getInputProps()} />
                                {data.poster.length === 0 ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '154.03px',
                                        height: '219.52px'
                                    }}>POSTER</Box>
                                ) : (
                                    <Box sx={{
                                        width: '154.03px',
                                        height: '219.52px'
                                    }} component="img" src={data.poster} />
                                )}
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column'
                        }}>
                            <TextField sx={{
                                marginBottom: '20px',
                                transition: 'opacity 0.4s',
                                opacity: isLoading ? '0.5 !important' : '1',
                                'input': {
                                    WebkitTextFillColor: 'unset !important',
                                    color: '#e1e6f0 !important'
                                },
                                'label': {
                                    color: '#707a94 !important'
                                },
                                'fieldset': {
                                    borderRadius: '8px',
                                    borderColor: '#707a94 !important'
                                },
                                '.Mui-focused fieldset': {
                                    borderColor: '#8f98b2 !important'
                                },
                                'label.MuiInputLabel-shrink': {
                                    color: '#8f98b2 !important'
                                }
                            }} label="Title" disabled={isLoading} autoComplete="off" value={data.title} onChange={e => setData({
                                ...data,
                                imdbId: '',
                                title: e.currentTarget.value
                            })} />
                            <TextField sx={{
                                transition: 'opacity 0.4s',
                                opacity: isLoading ? '0.5 !important' : '1',
                                'textarea': {
                                    height: '114px !important',
                                    WebkitTextFillColor: 'unset !important',
                                    color: '#e1e6f0 !important'
                                },
                                'label': {
                                    color: '#707a94 !important'
                                },
                                'fieldset': {
                                    borderRadius: '8px',
                                    borderColor: '#707a94 !important'
                                },
                                '.Mui-focused fieldset': {
                                    borderColor: '#8f98b2 !important'
                                },
                                'label.MuiInputLabel-shrink': {
                                    color: '#8f98b2 !important'
                                }
                            }} label="Description" disabled={isLoading} multiline={true} autoComplete="off" value={data.description} onChange={e => setData({
                                ...data,
                                description: e.currentTarget.value
                            })} />
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        marginBottom: '20px'
                    }}>
                        <Box sx={{
                            pointerEvents: isLoading ? 'none' : 'unset',
                            opacity: isLoading ? '0.5 !important' : '1',
                            height: '128px',
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px',
                            border: '2px dashed #707a94',
                            marginRight: '20px',
                            overflow: 'hidden',
                            cursor: 'pointer',
                            ':hover': {
                                borderColor: '#8f98b2'
                            }
                        }} {...logoDropzone.getRootProps()} onClick={(e) => {
                            setData({
                                ...data,
                                logo: ''
                            });

                            logoDropzone.getRootProps().onClick(e);
                        }}>
                            <Box component="input" {...logoDropzone.getInputProps()} />
                            {data.logo.length === 0 ? (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>LOGO</Box>
                            ) : (
                                <Box sx={{
                                    width: '40%'
                                }} component="img" src={data.logo} />
                            )}
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                marginBottom: '20px'
                            }}>
                                <FormControl sx={{
                                    marginRight: '20px',
                                    flex: 1
                                }} fullWidth>
                                    <InputLabel id="content-type-label" sx={{
                                        color: '#707a94 !important',
                                        '&.Mui-focused': {
                                            color: '#8f98b2 !important'
                                        }
                                    }}>Type</InputLabel>
                                    <Select sx={{
                                        transition: 'opacity 0.4s',
                                        opacity: isLoading ? '0.5 !important' : '1',
                                        '.MuiSelect-select': {
                                            WebkitTextFillColor: 'unset !important',
                                            color: '#e1e6f0 !important'
                                        },
                                        'fieldset': {
                                            borderRadius: '8px',
                                            borderColor: '#707a94 !important'
                                        },
                                        '.Mui-focused fieldset': {
                                            borderColor: '#8f98b2 !important'
                                        },
                                        'svg': {
                                            fill: '#8f98b2 !important'
                                        },
                                        '&.Mui-focused': {
                                            'svg': {
                                                fill: '#8f98b2 !important'
                                            }
                                        }
                                    }} labelId="content-type-label" defaultValue="movie" label="Type" disabled={isLoading} value={data.type} onChange={e => setData({
                                        ...data,
                                        type: e.target.value
                                    })}>
                                        <MenuItem value="movie">Movie</MenuItem>
                                        <MenuItem value="show">Show</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField sx={{
                                    flex: 1,
                                    transition: 'opacity 0.4s',
                                    opacity: isLoading ? '0.5 !important' : '1',
                                    'input': {
                                        WebkitTextFillColor: 'unset !important',
                                        color: '#e1e6f0 !important'
                                    },
                                    'label': {
                                        color: '#707a94 !important'
                                    },
                                    'fieldset': {
                                        borderRadius: '8px',
                                        borderColor: '#707a94 !important'
                                    },
                                    '.Mui-focused fieldset': {
                                        borderColor: '#8f98b2 !important'
                                    },
                                    'label.MuiInputLabel-shrink': {
                                        color: '#8f98b2 !important'
                                    }
                                }} label="Release year" disabled={isLoading} autoComplete="off" value={data.year} onChange={e => {
                                    if (e.currentTarget.value.length > 0 && (e.currentTarget.value.startsWith('0') || !/^\d+$/.test(e.currentTarget.value))) {
                                        return;
                                    }

                                    setData({
                                        ...data,
                                        imdbId: '',
                                        year: e.currentTarget.value
                                    });
                                }} />
                            </Box>
                            <Box sx={{
                                display: 'flex'
                            }}>
                                {data.type === 'show' ? (
                                    <Box sx={{
                                        flex: 1,
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        marginRight: '20px',
                                        background: 'rgb(0 0 0 / 50%)'
                                    }}></Box>
                                ) : (
                                    <TextField sx={{
                                        flex: 1,
                                        marginRight: '20px',
                                        transition: 'opacity 0.4s',
                                        opacity: isLoading ? '0.5 !important' : '1',
                                        'input': {
                                            WebkitTextFillColor: 'unset !important',
                                            color: '#e1e6f0 !important'
                                        },
                                        'label': {
                                            color: '#707a94 !important'
                                        },
                                        'fieldset': {
                                            borderRadius: '8px',
                                            borderColor: '#707a94 !important'
                                        },
                                        '.Mui-focused fieldset': {
                                            borderColor: '#8f98b2 !important'
                                        },
                                        'label.MuiInputLabel-shrink': {
                                            color: '#8f98b2 !important'
                                        }
                                    }} label="Duration in (ms)" disabled={isLoading} autoComplete="off" value={data.duration} onChange={e => {
                                        if (e.currentTarget.value.length > 0 && (e.currentTarget.value.startsWith('0') || !/^\d+$/.test(e.currentTarget.value))) {
                                            return;
                                        }

                                        setData({
                                            ...data,
                                            duration: e.currentTarget.value
                                        });
                                    }} />
                                )}
                                <TextField sx={{
                                    flex: 1,
                                    transition: 'opacity 0.4s',
                                    opacity: isLoading ? '0.5 !important' : '1',
                                    'input': {
                                        WebkitTextFillColor: 'unset !important',
                                        color: '#e1e6f0 !important'
                                    },
                                    'label': {
                                        color: '#707a94 !important'
                                    },
                                    'fieldset': {
                                        borderRadius: '8px',
                                        borderColor: '#707a94 !important'
                                    },
                                    '.Mui-focused fieldset': {
                                        borderColor: '#8f98b2 !important'
                                    },
                                    'label.MuiInputLabel-shrink': {
                                        color: '#8f98b2 !important'
                                    }
                                }} label="Maturity rating" disabled={isLoading} autoComplete="off" value={data.maturityRating} onChange={e => {
                                    if (e.currentTarget.value.length > 0 && (e.currentTarget.value.startsWith('0') || !/^\d+$/.test(e.currentTarget.value))) {
                                        return;
                                    }

                                    setData({
                                        ...data,
                                        maturityRating: e.currentTarget.value
                                    });
                                }} />
                            </Box>
                        </Box>
                    </Box>
                    <Autocomplete sx={{
                        marginBottom: '20px',
                        flex: 1
                    }} multiple filterSelectedOptions options={Object.keys(genres).map(Number)} getOptionLabel={option => genres[option.toString()]} value={data.genres} onChange={(_, value) => setData({
                        ...data,
                        genres: value
                    })} disabled={isLoading} renderInput={(params) => (
                        <TextField sx={{
                            transition: 'opacity 0.4s',
                            opacity: isLoading ? '0.5 !important' : '1',
                            'input': {
                                WebkitTextFillColor: 'unset !important',
                                color: '#e1e6f0 !important'
                            },
                            'label': {
                                color: '#707a94 !important'
                            },
                            'fieldset': {
                                borderRadius: '8px',
                                borderColor: '#707a94 !important'
                            },
                            '.Mui-focused fieldset': {
                                borderColor: '#8f98b2 !important'
                            },
                            'label.MuiInputLabel-shrink': {
                                color: '#8f98b2 !important'
                            },
                            'svg': {
                                fill: '#8f98b2 !important'
                            },
                            '.Mui-focused': {
                                'svg': {
                                    fill: '#8f98b2 !important'
                                }
                            },
                            '.MuiChip-label': {
                                color: '#ffffff'
                            },
                            '.MuiChip-root': {
                                backgroundColor: 'rgb(0 0 0 / 50%)'
                            }
                        }} label="Genres" {...params} />
                    )}
                    />
                    <Box sx={{
                        marginBottom: '20px',
                        display: 'flex'
                    }}>
                        <TextField sx={{
                            flex: 1,
                            marginRight: '20px',
                            transition: 'opacity 0.4s',
                            opacity: isLoading ? '0.5 !important' : '1',
                            'input': {
                                WebkitTextFillColor: 'unset !important',
                                color: '#e1e6f0 !important'
                            },
                            'label': {
                                color: '#707a94 !important'
                            },
                            'fieldset': {
                                borderRadius: '8px',
                                borderColor: '#707a94 !important'
                            },
                            '.Mui-focused fieldset': {
                                borderColor: '#8f98b2 !important'
                            },
                            'label.MuiInputLabel-shrink': {
                                color: '#8f98b2 !important'
                            }
                        }} label="Trailer (YouTube Video ID)" disabled={isLoading} autoComplete="off" value={data.trailer} onChange={e => setData({
                            ...data,
                            trailer: e.currentTarget.value
                        })} />
                        <TextField sx={{
                            flex: 1,
                            transition: 'opacity 0.4s',
                            opacity: isLoading ? '0.5 !important' : '1',
                            'input': {
                                WebkitTextFillColor: 'unset !important',
                                color: '#e1e6f0 !important'
                            },
                            'label': {
                                color: '#707a94 !important'
                            },
                            'fieldset': {
                                borderRadius: '8px',
                                borderColor: '#707a94 !important'
                            },
                            '.Mui-focused fieldset': {
                                borderColor: '#8f98b2 !important'
                            },
                            'label.MuiInputLabel-shrink': {
                                color: '#8f98b2 !important'
                            }
                        }} label="IMDB ID" disabled={isLoading} autoComplete="off" value={data.imdbId} onChange={e => setData({
                            ...data,
                            imdbId: e.currentTarget.value
                        })} />
                    </Box>
                    <TextField sx={{
                        marginBottom: '20px',
                        transition: 'opacity 0.4s',
                        opacity: isLoading ? '0.5 !important' : '1',
                        'textarea': {
                            WebkitTextFillColor: 'unset !important',
                            color: '#e1e6f0 !important'
                        },
                        'label': {
                            color: '#707a94 !important'
                        },
                        'fieldset': {
                            borderRadius: '8px',
                            borderColor: '#707a94 !important'
                        },
                        '.Mui-focused fieldset': {
                            borderColor: '#8f98b2 !important'
                        },
                        'label.MuiInputLabel-shrink': {
                            color: '#8f98b2 !important'
                        }
                    }} label="Keywords" placeholder="List of keywords, separated by a line break" disabled={isLoading} multiline={true} rows={5} autoComplete="off" value={data.keywords.join('\n')} onChange={e => setData({
                        ...data,
                        keywords: e.currentTarget.value.split('\n')
                    })} />
                    {data.type === 'movie' ? (
                        <TextField sx={{
                            marginBottom: '20px',
                            transition: 'opacity 0.4s',
                            opacity: isLoading ? '0.5 !important' : '1',
                            'textarea': {
                                WebkitTextFillColor: 'unset !important',
                                color: '#e1e6f0 !important'
                            },
                            'label': {
                                color: '#707a94 !important'
                            },
                            'fieldset': {
                                borderRadius: '8px',
                                borderColor: '#707a94 !important'
                            },
                            '.Mui-focused fieldset': {
                                borderColor: '#8f98b2 !important'
                            },
                            'label.MuiInputLabel-shrink': {
                                color: '#8f98b2 !important'
                            }
                        }} label="Watch servers" placeholder="List of urls, separated by a line break" disabled={isLoading} multiline={true} rows={5} autoComplete="off" value={data.watchServers.join('\n')} onChange={e => setData({
                            ...data,
                            watchServers: e.currentTarget.value.split('\n')
                        })} />
                    ) : (
                        <>
                            <Box sx={{
                                marginBottom: '20px',
                                padding: '20px',
                                display: 'flex',
                                flexDirection: 'row',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderRadius: '8px',
                                borderColor: '#707a94'
                            }} component="fieldset">
                                <Box sx={{
                                    padding: '0 5px'
                                }} component="legend">
                                    <Breadcrumbs sx={{
                                        color: '#707a94'
                                    }} separator={<NavigateNextIcon fontSize="small" />}>
                                        {seasonsBreadcrumbs.seasonNum === null ? (
                                            <Box>Seasons</Box>
                                        ) : (
                                            <Box>Season {seasonsBreadcrumbs.seasonNum}</Box>
                                        )}
                                        {seasonsBreadcrumbs.episodeNum === null ? null : (
                                            <Box>Episode {seasonsBreadcrumbs.episodeNum}</Box>
                                        )}
                                    </Breadcrumbs>
                                </Box>
                                {seasonsBreadcrumbs.seasonNum === null ? (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%'
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            marginBottom: '12px'
                                        }}>
                                            {data.seasons.map((_, seasonIndex) => (
                                                <Button sx={{
                                                    marginRight: '8px',
                                                    marginBottom: '8px',
                                                    padding: '8px 14px',
                                                    transition: 'background 0.2s ease',
                                                    borderRadius: '8px',
                                                    alignSelf: 'center',
                                                    fontSize: '16px',
                                                    color: '#e1e6f0 !important',
                                                    textTransform: 'unset',
                                                    background: '#5674b1',
                                                    ':hover': {
                                                        background: '#4a6494'
                                                    },
                                                    ':active': {
                                                        background: '#3e517f'
                                                    },
                                                    'span': {
                                                        display: 'none'
                                                    },
                                                    ':disabled': {
                                                        opacity: 0.2
                                                    }
                                                }} disabled={isLoading} onClick={() => setSeasonsBreadcrumbs({
                                                    ...seasonsBreadcrumbs,
                                                    seasonNum: seasonIndex + 1,
                                                    episodeNum: null
                                                })}>Season {seasonIndex + 1}</Button>
                                            ))}
                                        </Box>
                                        <Box sx={{
                                            display: 'flex'
                                        }}>
                                            <Button sx={{
                                                marginRight: '8px',
                                                width: '100%',
                                                padding: '8px 14px',
                                                transition: 'transform 0.2s, opacity 0.4s',
                                                borderRadius: '8px',
                                                alignSelf: 'center',
                                                fontSize: '16px',
                                                color: '#ffffff !important',
                                                textTransform: 'unset',
                                                background: 'linear-gradient(93.87deg, #6dbf7f, #4f9262)',
                                                ':hover': {
                                                    transform: 'scale(1.01)'
                                                },
                                                ':active': {
                                                    transform: 'scale(0.98)'
                                                },
                                                'span': {
                                                    display: 'none'
                                                },
                                                ':disabled': {
                                                    opacity: 0.2
                                                }
                                            }} disabled={isLoading} onClick={() => {
                                                const seasons = data.seasons;
                                                seasons.push({
                                                    title: '',
                                                    description: '',
                                                    thumb: '',
                                                    trailer: '',
                                                    keywords: [],
                                                    episodes: [
                                                        {
                                                            title: '',
                                                            description: '',
                                                            thumb: '',
                                                            trailer: '',
                                                            duration: '',
                                                            keywords: [],
                                                            watchServers: []
                                                        }
                                                    ]
                                                });

                                                setData({
                                                    ...data,
                                                    seasons: seasons
                                                });
                                            }}>+</Button>
                                            <Button sx={{
                                                width: '100%',
                                                padding: '8px 14px',
                                                transition: 'background 0.2s, opacity 0.4s',
                                                borderRadius: '8px',
                                                alignSelf: 'center',
                                                fontSize: '16px',
                                                color: '#ffffff !important',
                                                textTransform: 'unset',
                                                background: '#9e5151',
                                                ':hover': {
                                                    background: '#824343'
                                                },
                                                ':active': {
                                                    background: '#6b3737'
                                                },
                                                'span': {
                                                    display: 'none'
                                                },
                                                ':disabled': {
                                                    opacity: 0.2
                                                }
                                            }} disabled={isLoading || data.seasons.length === 1} onClick={() => {
                                                const seasons = data.seasons;
                                                seasons.pop();

                                                setData({
                                                    ...data,
                                                    seasons: seasons
                                                });
                                            }}>-</Button>
                                        </Box>
                                    </Box>
                                ) : seasonsBreadcrumbs.episodeNum === null ? (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%'
                                    }}>
                                        <TextField sx={{
                                            marginBottom: '20px',
                                            transition: 'opacity 0.4s',
                                            opacity: isLoading ? '0.5 !important' : '1',
                                            'input': {
                                                WebkitTextFillColor: 'unset !important',
                                                color: '#e1e6f0 !important'
                                            },
                                            'label': {
                                                color: '#707a94 !important'
                                            },
                                            'fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#707a94 !important'
                                            },
                                            '.Mui-focused fieldset': {
                                                borderColor: '#8f98b2 !important'
                                            },
                                            'label.MuiInputLabel-shrink': {
                                                color: '#8f98b2 !important'
                                            }
                                        }} label="Title" disabled={isLoading} autoComplete="off" value={data.seasons[seasonsBreadcrumbs.seasonNum - 1].title} onChange={e => {
                                            const seasons = data.seasons;
                                            seasons[seasonsBreadcrumbs.seasonNum - 1].title = e.currentTarget.value;

                                            setData({
                                                ...data,
                                                seasons: seasons
                                            });
                                        }} />
                                        <TextField sx={{
                                            marginBottom: '20px',
                                            transition: 'opacity 0.4s',
                                            opacity: isLoading ? '0.5 !important' : '1',
                                            'textarea': {
                                                WebkitTextFillColor: 'unset !important',
                                                color: '#e1e6f0 !important'
                                            },
                                            'label': {
                                                color: '#707a94 !important'
                                            },
                                            'fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#707a94 !important'
                                            },
                                            '.Mui-focused fieldset': {
                                                borderColor: '#8f98b2 !important'
                                            },
                                            'label.MuiInputLabel-shrink': {
                                                color: '#8f98b2 !important'
                                            }
                                        }} label="Description" disabled={isLoading} multiline={true} rows={3} autoComplete="off" value={data.seasons[seasonsBreadcrumbs.seasonNum - 1].description} onChange={e => {
                                            const seasons = data.seasons;
                                            seasons[seasonsBreadcrumbs.seasonNum - 1].description = e.currentTarget.value;

                                            setData({
                                                ...data,
                                                seasons: seasons
                                            });
                                        }} />
                                        <TextField sx={{
                                            marginBottom: '20px',
                                            transition: 'opacity 0.4s',
                                            opacity: isLoading ? '0.5 !important' : '1',
                                            'textarea': {
                                                WebkitTextFillColor: 'unset !important',
                                                color: '#e1e6f0 !important'
                                            },
                                            'label': {
                                                color: '#707a94 !important'
                                            },
                                            'fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#707a94 !important'
                                            },
                                            '.Mui-focused fieldset': {
                                                borderColor: '#8f98b2 !important'
                                            },
                                            'label.MuiInputLabel-shrink': {
                                                color: '#8f98b2 !important'
                                            }
                                        }} label="Keywords" placeholder="List of keywords, separated by a line break" disabled={isLoading} multiline={true} rows={5} autoComplete="off" value={data.seasons[seasonsBreadcrumbs.seasonNum - 1].keywords.join('\n')} onChange={e => {
                                            const seasons = data.seasons;
                                            seasons[seasonsBreadcrumbs.seasonNum - 1].keywords = e.currentTarget.value.split('\n');

                                            setData({
                                                ...data,
                                                seasons: seasons
                                            });
                                        }} />
                                        <Box sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            marginBottom: '12px'
                                        }}>
                                            {data.seasons[seasonsBreadcrumbs.seasonNum - 1].episodes.map((_, episodeIndex) => (
                                                <Button sx={{
                                                    marginRight: '8px',
                                                    marginBottom: '8px',
                                                    padding: '8px 14px',
                                                    transition: 'background 0.2s ease',
                                                    borderRadius: '8px',
                                                    alignSelf: 'center',
                                                    fontSize: '16px',
                                                    color: '#e1e6f0 !important',
                                                    textTransform: 'unset',
                                                    background: '#5674b1',
                                                    ':hover': {
                                                        background: '#4a6494'
                                                    },
                                                    ':active': {
                                                        background: '#3e517f'
                                                    },
                                                    'span': {
                                                        display: 'none'
                                                    },
                                                    ':disabled': {
                                                        opacity: 0.2
                                                    }
                                                }} disabled={isLoading} onClick={() => setSeasonsBreadcrumbs({
                                                    ...seasonsBreadcrumbs,
                                                    episodeNum: episodeIndex + 1
                                                })}>Episode {episodeIndex + 1}</Button>
                                            ))}
                                        </Box>
                                        <Box sx={{
                                            display: 'flex'
                                        }}>
                                            <Button sx={{
                                                marginRight: '8px',
                                                width: '100%',
                                                padding: '8px 14px',
                                                transition: 'background 0.2s, opacity 0.4s',
                                                borderRadius: '8px',
                                                alignSelf: 'center',
                                                fontSize: '16px',
                                                color: '#ffffff !important',
                                                textTransform: 'unset',
                                                background: '#5674b1',
                                                ':hover': {
                                                    background: '#4a6494'
                                                },
                                                ':active': {
                                                    background: '#3e517f'
                                                },
                                                'span': {
                                                    display: 'none'
                                                },
                                                ':disabled': {
                                                    opacity: 0.2
                                                }
                                            }} disabled={isLoading} onClick={() => {
                                                setSeasonsBreadcrumbs({
                                                    ...seasonsBreadcrumbs,
                                                    seasonNum: null
                                                });
                                            }}>
                                                <NavigateBeforeIcon />
                                            </Button>
                                            <Button sx={{
                                                marginRight: '8px',
                                                width: '100%',
                                                padding: '8px 14px',
                                                transition: 'transform 0.2s, opacity 0.4s',
                                                borderRadius: '8px',
                                                alignSelf: 'center',
                                                fontSize: '16px',
                                                color: '#ffffff !important',
                                                textTransform: 'unset',
                                                background: 'linear-gradient(93.87deg, #6dbf7f, #4f9262)',
                                                ':hover': {
                                                    transform: 'scale(1.01)'
                                                },
                                                ':active': {
                                                    transform: 'scale(0.98)'
                                                },
                                                'span': {
                                                    display: 'none'
                                                },
                                                ':disabled': {
                                                    opacity: 0.2
                                                }
                                            }} disabled={isLoading} onClick={() => {
                                                const seasons = data.seasons;
                                                seasons[seasonsBreadcrumbs.seasonNum - 1].episodes.push({
                                                    title: '',
                                                    description: '',
                                                    thumb: '',
                                                    trailer: '',
                                                    duration: '',
                                                    keywords: [],
                                                    watchServers: []
                                                });

                                                setData({
                                                    ...data,
                                                    seasons: seasons
                                                });
                                            }}>+</Button>
                                            <Button sx={{
                                                width: '100%',
                                                padding: '8px 14px',
                                                transition: 'background 0.2s, opacity 0.4s',
                                                borderRadius: '8px',
                                                alignSelf: 'center',
                                                fontSize: '16px',
                                                color: '#ffffff !important',
                                                textTransform: 'unset',
                                                background: '#9e5151',
                                                ':hover': {
                                                    background: '#824343'
                                                },
                                                ':active': {
                                                    background: '#6b3737'
                                                },
                                                'span': {
                                                    display: 'none'
                                                },
                                                ':disabled': {
                                                    opacity: 0.2
                                                }
                                            }} disabled={isLoading || data.seasons[seasonsBreadcrumbs.seasonNum - 1].episodes.length === 1} onClick={() => {
                                                const seasons = data.seasons;
                                                seasons[seasonsBreadcrumbs.seasonNum - 1].episodes.pop();

                                                setData({
                                                    ...data,
                                                    seasons: seasons
                                                });
                                            }}>-</Button>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%'
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            marginBottom: '20px'
                                        }}>
                                            <TextField sx={{
                                                flex: 1,
                                                marginRight: '20px',
                                                transition: 'opacity 0.4s',
                                                opacity: isLoading ? '0.5 !important' : '1',
                                                'input': {
                                                    WebkitTextFillColor: 'unset !important',
                                                    color: '#e1e6f0 !important'
                                                },
                                                'label': {
                                                    color: '#707a94 !important'
                                                },
                                                'fieldset': {
                                                    borderRadius: '8px',
                                                    borderColor: '#707a94 !important'
                                                },
                                                '.Mui-focused fieldset': {
                                                    borderColor: '#8f98b2 !important'
                                                },
                                                'label.MuiInputLabel-shrink': {
                                                    color: '#8f98b2 !important'
                                                }
                                            }} label="Title" disabled={isLoading} autoComplete="off" value={data.seasons[seasonsBreadcrumbs.seasonNum - 1].episodes[seasonsBreadcrumbs.episodeNum - 1].title} onChange={e => {
                                                const seasons = data.seasons;
                                                seasons[seasonsBreadcrumbs.seasonNum - 1].episodes[seasonsBreadcrumbs.episodeNum - 1].title = e.currentTarget.value;

                                                setData({
                                                    ...data,
                                                    seasons: seasons
                                                });
                                            }} />
                                            <TextField sx={{
                                                flex: 1,
                                                transition: 'opacity 0.4s',
                                                opacity: isLoading ? '0.5 !important' : '1',
                                                'input': {
                                                    WebkitTextFillColor: 'unset !important',
                                                    color: '#e1e6f0 !important'
                                                },
                                                'label': {
                                                    color: '#707a94 !important'
                                                },
                                                'fieldset': {
                                                    borderRadius: '8px',
                                                    borderColor: '#707a94 !important'
                                                },
                                                '.Mui-focused fieldset': {
                                                    borderColor: '#8f98b2 !important'
                                                },
                                                'label.MuiInputLabel-shrink': {
                                                    color: '#8f98b2 !important'
                                                }
                                            }} label="Duration in (ms)" disabled={isLoading} autoComplete="off" value={data.seasons[seasonsBreadcrumbs.seasonNum - 1].episodes[seasonsBreadcrumbs.episodeNum - 1].duration} onChange={e => {
                                                if (e.currentTarget.value.length > 0 && (e.currentTarget.value.startsWith('0') || !/^\d+$/.test(e.currentTarget.value))) {
                                                    return;
                                                }

                                                const seasons = data.seasons;
                                                seasons[seasonsBreadcrumbs.seasonNum - 1].episodes[seasonsBreadcrumbs.episodeNum - 1].duration = e.currentTarget.value;

                                                setData({
                                                    ...data,
                                                    seasons: seasons
                                                });
                                            }} />
                                        </Box>
                                        <TextField sx={{
                                            marginBottom: '20px',
                                            transition: 'opacity 0.4s',
                                            opacity: isLoading ? '0.5 !important' : '1',
                                            'textarea': {
                                                WebkitTextFillColor: 'unset !important',
                                                color: '#e1e6f0 !important'
                                            },
                                            'label': {
                                                color: '#707a94 !important'
                                            },
                                            'fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#707a94 !important'
                                            },
                                            '.Mui-focused fieldset': {
                                                borderColor: '#8f98b2 !important'
                                            },
                                            'label.MuiInputLabel-shrink': {
                                                color: '#8f98b2 !important'
                                            }
                                        }} label="Description" disabled={isLoading} multiline={true} rows={3} autoComplete="off" value={data.seasons[seasonsBreadcrumbs.seasonNum - 1].episodes[seasonsBreadcrumbs.episodeNum - 1].description} onChange={e => {
                                            const seasons = data.seasons;
                                            seasons[seasonsBreadcrumbs.seasonNum - 1].episodes[seasonsBreadcrumbs.episodeNum - 1].description = e.currentTarget.value;

                                            setData({
                                                ...data,
                                                seasons: seasons
                                            });
                                        }} />
                                        <TextField sx={{
                                            marginBottom: '20px',
                                            transition: 'opacity 0.4s',
                                            opacity: isLoading ? '0.5 !important' : '1',
                                            'textarea': {
                                                WebkitTextFillColor: 'unset !important',
                                                color: '#e1e6f0 !important'
                                            },
                                            'label': {
                                                color: '#707a94 !important'
                                            },
                                            'fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#707a94 !important'
                                            },
                                            '.Mui-focused fieldset': {
                                                borderColor: '#8f98b2 !important'
                                            },
                                            'label.MuiInputLabel-shrink': {
                                                color: '#8f98b2 !important'
                                            }
                                        }} label="Keywords" placeholder="List of keywords, separated by a line break" disabled={isLoading} multiline={true} rows={5} autoComplete="off" value={data.seasons[seasonsBreadcrumbs.seasonNum - 1].episodes[seasonsBreadcrumbs.episodeNum - 1].keywords.join('\n')} onChange={e => {
                                            const seasons = data.seasons;
                                            seasons[seasonsBreadcrumbs.seasonNum - 1].episodes[seasonsBreadcrumbs.episodeNum - 1].keywords = e.currentTarget.value.split('\n');

                                            setData({
                                                ...data,
                                                seasons: seasons
                                            });
                                        }} />
                                        <TextField sx={{
                                            marginBottom: '20px',
                                            transition: 'opacity 0.4s',
                                            opacity: isLoading ? '0.5 !important' : '1',
                                            'textarea': {
                                                WebkitTextFillColor: 'unset !important',
                                                color: '#e1e6f0 !important'
                                            },
                                            'label': {
                                                color: '#707a94 !important'
                                            },
                                            'fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#707a94 !important'
                                            },
                                            '.Mui-focused fieldset': {
                                                borderColor: '#8f98b2 !important'
                                            },
                                            'label.MuiInputLabel-shrink': {
                                                color: '#8f98b2 !important'
                                            }
                                        }} label="Watch servers" placeholder="List of urls, separated by a line break" disabled={isLoading} multiline={true} rows={5} autoComplete="off" value={data.seasons[seasonsBreadcrumbs.seasonNum - 1].episodes[seasonsBreadcrumbs.episodeNum - 1].watchServers.join('\n')} onChange={e => {
                                            const seasons = data.seasons;
                                            seasons[seasonsBreadcrumbs.seasonNum - 1].episodes[seasonsBreadcrumbs.episodeNum - 1].watchServers = e.currentTarget.value.split('\n');

                                            setData({
                                                ...data,
                                                seasons: seasons
                                            });
                                        }} />
                                        <Box sx={{
                                            display: 'flex'
                                        }}>
                                            <Button sx={{
                                                marginRight: '8px',
                                                width: '100%',
                                                padding: '8px 14px',
                                                transition: 'background 0.2s, opacity 0.4s',
                                                borderRadius: '8px',
                                                alignSelf: 'center',
                                                fontSize: '16px',
                                                color: '#ffffff !important',
                                                textTransform: 'unset',
                                                background: '#5674b1',
                                                ':hover': {
                                                    background: '#4a6494'
                                                },
                                                ':active': {
                                                    background: '#3e517f'
                                                },
                                                'span': {
                                                    display: 'none'
                                                },
                                                ':disabled': {
                                                    opacity: 0.2
                                                }
                                            }} disabled={isLoading} onClick={() => {
                                                setSeasonsBreadcrumbs({
                                                    ...seasonsBreadcrumbs,
                                                    episodeNum: null
                                                });
                                            }}>
                                                <NavigateBeforeIcon />
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Button sx={{
                            padding: '8px 14px',
                            transition: 'background 0.2s ease',
                            borderRadius: '8px',
                            alignSelf: 'center',
                            fontSize: '16px',
                            color: '#e1e6f0 !important',
                            textTransform: 'unset',
                            background: '#5674b1',
                            ':hover': {
                                background: '#4a6494'
                            },
                            ':active': {
                                background: '#3e517f'
                            },
                            'span': {
                                display: 'none'
                            },
                            ':disabled': {
                                opacity: 0.2
                            }
                        }} disabled={data.title.length === 0 || data.year.length === 0} onClick={() => {
                            setLoading(true);

                            fetch(`https://www.omdbapi.com/?apikey=e2320a03&t=${encodeURIComponent(data.title)}&type=${data.type === 'show' ? 'series' : data.type}&y=${data.year}`).then(async omdbRes => {
                                if (omdbRes.status === 200) {
                                    const json = await omdbRes.json();

                                    if (json['Response'] === 'True') {
                                        const newData = {
                                            watchServers: [
                                                `https://vidsrc.cc/v2/embed/movie/${json['imdbID']}`
                                            ],
                                            keywords: [
                                                `فيلم ${json['Title']}`,
                                                `فيلم ${json['Title']} مترجم`,
                                                `فيلم ${json['Title']} مترجم كامل`
                                            ],
                                            imdbId: json['imdbID'],
                                            title: json['Title'],
                                            description: json['Plot'],
                                            year: json['Year'].split('–')[0],
                                            duration: data.type === 'movie' ? ((parseInt(json['Runtime'].split(' ')[0]) * 60 * 1000) || '').toString() : '',
                                            maturityRating: (() => {
                                                return {
                                                    'G': '0',
                                                    'PG': '8',
                                                    'PG-13': '13',
                                                    'R': '17',
                                                    'NC-17': '18',
                                                    'TV-Y': '0',
                                                    'TV-Y7': '7',
                                                    'TV-G': '0',
                                                    'TV-PG': '8',
                                                    'TV-14': '14',
                                                    'TV-MA': '17',
                                                    'U': '0',
                                                    '12A': '12',
                                                    '15': '15',
                                                    '18': '18',
                                                    'N/A': ''
                                                }[json['Rated']] || ''
                                            })(),
                                            genres: [...new Set(json['Genre'].split(', ').reduce((acc, genre) => {
                                                const genreId = Object.keys(genres).find(key => genres[key] === genre);
                                                return genreId !== undefined ? [...acc, parseInt(genreId)] : acc;
                                            }, data.genres))]
                                        };

                                        const tmdbRes = await fetch(`https://api.themoviedb.org/3/${data.type === 'show' ? 'series' : data.type}/${newData.imdbId}/videos?language=en-US`, {
                                            method: 'GET',
                                            headers: {
                                                accept: 'application/json',
                                                Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI1YjYxZjc2YWZjYWFhODNmNTA5MGVjOWM3YTU1YWU0YyIsIm5iZiI6MTcyNTAxMDc0OC41MzM2NjQsInN1YiI6IjY2YzFjMTZjYjg2NTNmMmUxZDg5YTM3YyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._NymHOQyuC_LqR9_w1fbxQzHLEpc-a1B7YBewyXT4Rc'
                                            }
                                        });

                                        if (tmdbRes.status === 200) {
                                            const json = await tmdbRes.json();

                                            if (json['results'].length > 0) {
                                                for (const result of json['results'].reverse()) {
                                                    if (result['official'] === true && result['type'] === 'Trailer') {
                                                        newData.trailer = result['key'];
                                                    }
                                                }
                                            }
                                        }

                                        setData({
                                            ...data,
                                            ...newData
                                        });
                                    }

                                    setLoading(false);
                                }
                            }).catch(() => setLoading(false));
                        }}>
                            <Box>Auto Fill Up</Box>
                        </Button>
                        <Button sx={{
                            padding: '8px 14px',
                            minHeight: '54px',
                            transition: 'transform 0.2s, opacity 0.4s',
                            borderRadius: '8px',
                            alignSelf: 'center',
                            width: '200px',
                            fontSize: '24px',
                            color: '#ffffff !important',
                            textTransform: 'unset',
                            background: 'linear-gradient(93.87deg,#ddc36c,#b19c56)',
                            ':hover': {
                                transform: 'scale(1.04)'
                            },
                            ':active': {
                                transform: 'scale(0.96)'
                            },
                            'span': {
                                display: 'none'
                            },
                            ':disabled': {
                                opacity: 0.2
                            }
                        }} disabled={Object.values(data).some(x => x.length === 0) || isLoading} onClick={() => {
                            setLoading(true);

                            toast.promise(new Promise(async (resolve, reject) => {
                                const newData = { ...data };
                                newData.title = data.title.trim();
                                newData.description = data.description.trim();
                                newData.year = parseInt(data.year);

                                if (newData.type === 'movie') {
                                    newData.duration = parseInt(data.duration);
                                    delete newData.seasons;
                                } else {
                                    delete newData.duration;

                                    for (let i = 0; i < newData.seasons.length; i++) {
                                        newData.seasons[i].title = newData.seasons[i].title.trim();
                                        newData.seasons[i].description = newData.seasons[i].description.trim();

                                        for (let j = 0; j < newData.seasons[i].keywords.length; j++) {
                                            newData.seasons[i].keywords[j] = newData.seasons[i].keywords[j].trim();
                                        }

                                        for (let j = 0; j < newData.seasons[i].episodes.length; j++) {
                                            newData.seasons[i].episodes[j].title = newData.seasons[i].episodes[j].title.trim();
                                            newData.seasons[i].episodes[j].description = newData.seasons[i].episodes[j].description.trim();
                                            newData.seasons[i].episodes[j].duration = parseInt(newData.seasons[i].episodes[j].duration);

                                            for (let k = 0; k < newData.seasons[i].episodes[j].keywords.length; k++) {
                                                newData.seasons[i].episodes[j].keywords[k] = newData.seasons[i].episodes[j].keywords[k].trim();
                                            }

                                            for (let k = 0; k < newData.seasons[i].episodes[j].watchServers.length; k++) {
                                                newData.seasons[i].episodes[j].watchServers[k] = newData.seasons[i].episodes[j].watchServers[k].trim();
                                            }
                                        }
                                    }
                                }

                                newData.maturityRating = parseInt(data.maturityRating);

                                for (let i = 0; i < newData.keywords.length; i++) {
                                    newData.keywords[i] = newData.keywords[i].trim();
                                }

                                for (let i = 0; i < newData.watchServers.length; i++) {
                                    newData.watchServers[i] = newData.watchServers[i].trim();
                                }

                                try {
                                    const response = await fetch('/api/content/add/', {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            ...newData,
                                            auth: localStorage.getItem('auth')
                                        })
                                    });

                                    if (response.status === 200) {
                                        const status = (await response.json())['status'];

                                        if (status === 0) {
                                            setData({
                                                imdbId: '',
                                                type: 'movie',
                                                title: '',
                                                description: '',
                                                poster: '',
                                                thumb: '',
                                                logo: '',
                                                trailer: '',
                                                year: '',
                                                duration: '',
                                                maturityRating: '',
                                                genres: [],
                                                keywords: [],
                                                watchServers: [],
                                                seasons: [
                                                    {
                                                        title: '',
                                                        description: '',
                                                        thumb: '',
                                                        trailer: '',
                                                        keywords: [],
                                                        episodes: [
                                                            {
                                                                title: '',
                                                                description: '',
                                                                thumb: '',
                                                                trailer: '',
                                                                duration: '',
                                                                keywords: [],
                                                                watchServers: []
                                                            }
                                                        ]
                                                    }
                                                ]
                                            });

                                            resolve();
                                        } else if (status === 1) {
                                            reject(1);
                                        }
                                    } else {
                                        reject();
                                    }
                                } catch {
                                    reject();
                                }

                                setLoading(false);
                            }), {
                                pending: {
                                    type: 'info',
                                    theme: 'dark',
                                    render: 'Processing your request...'
                                },
                                success: {
                                    type: 'success',
                                    theme: 'dark',
                                    render: 'Content added successfully.'
                                },
                                error: {
                                    type: 'error',
                                    theme: 'dark',
                                    render({ data }) {
                                        return data === 1 ? 'An error occurred. Unable to add content (Already added).' : 'An error occurred. Unable to add content.'
                                    }
                                }
                            });
                        }}>
                            <Box>Add</Box>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
        </Box>
    )
}

export default AddContent;
